<template>
  <div>
    <v-container v-if="!is_404">
      <v-card class="p-2" v-if="is_completed">
        <i class="text-h5">#{{ collection.remote_id }}</i>
        <br /><span class="text-h4" style="font-weight: 550">{{ collection.title }}</span>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <span
          >Total <strong>{{ collection.tally }}</strong> products</span
        >
      </v-card>
    </v-container>

    <v-container v-else>
      <div class="text-center">
        <img :src="require('@/assets/images/misc/no-data.png')" alt="" width="500px" />

        <p class="text-h4">It's either not your collection or it doesn't exist ;) </p>
      </div>
    </v-container>
    <products v-if="collection_id && is_completed" :collection="collection_id" />
  </div>
</template>

<script>
import Products from './../Products/Products.vue'
import store from '@/store'
export default {
  name: 'CollectionProducts',
  data() {
    return {
      is_completed: false,
      collection_id: null,
      shop: null,
      collection: {},
      is_404: false,
    }
  },
  components: {
    Products,
  },
  mounted() {
    this.shop = store.getters.loggedInShop
    this.collection_id = this.$route.params.collection_id
    this.fetchCollectionDetail()
  },

  methods: {
    async fetchCollectionDetail() {
      try {
        let response = await axios.get(
          `admin/shops/${this.shop.id}/shopify-collections/${this.collection_id}/get-detail`,
        )
        if (response.data.success) {
          this.collection = response.data.data
          this.is_completed = true
        }
      } catch (e) {
        if (e.response.status == 400) {
          this.is_404 = true
        }
      } finally {
        this.is_loading = false
      }
    },
  },
}
</script>